export const products = [
  {
    id: 1,
    img: require("./img/cryptoss.png").default,
    link: "https://karyakarsa.com/lullaby/cryptonews-app-114722",
  },
  {
    id: 2,
    img: require("./img/skss.png").default,
    link: "https://karyakarsa.com/lullaby/susunkatla",
  },
  {
    id: 3,
    img: require("./img/laundryss.png").default,
    link: "https://github.com/Lullaby16/laundry-app",
  },
];
